// ChooseLocation.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from './Nav'
import { useLoadScript } from '@react-google-maps/api';
import Autocomplete from 'react-google-autocomplete';
import MenuHeader from './MenuHeader';
import { useLocation } from 'react-router-dom';
import AddressService from '../services/address.service';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNotifications } from '../context/NotificationProvider';
import Modal from "react-modal";
import ZoneService from '../services/zone.service';
import croix from "../assets/images/close-black.png";

const customStyles = {
  content: {
    width: "33%",
    top: "50%",
    padding: "40px",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "10px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "450px",
    margin: "auto",
    textAlign: "center",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};


Modal.setAppElement("#root");

const libraries = ['places'];
const ChooseLocation = () => {

  const navigate = useNavigate();
  const notify = useNotifications();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514',
    libraries,
  });
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [hotelAddress, setHotelAddress] = useState();
  const [address, setAddress] = useState({
    street: '',
    city: '',
    zip_code: '',
    street_number: '',
    state: '',
    state_code: '',
    country: '',
    country_code: '',
    lat: null,
    lng: null,
  });

  const [email, setEmail] = useState("");
  const handleSubmitEmail = (e) => {
    e.preventDefault();
    // Logic to handle email submission
    console.log("Email submitted: ", email);
  };
  const loc = useLocation();
  const closeModal = () => setIsOpen(false);
  const closeModal2 = () => setIsOpen2(false);
  const openModal = () => setIsOpen(true);
  const openModal2 = () => {

    setIsOpen2(true)
  };

  const onConfirm = () => {
    navigate('/hotels');
  };

  const onContact = () => {
    navigate('/contact');
  };
  const { selectedServices, duo } = loc.state || JSON.parse(sessionStorage.getItem('state') ?? '{}');

  useEffect(() => {
    const savedState = JSON.parse(sessionStorage.getItem('state') ?? '{}');
    if (savedState?.address) {
      setAddress(savedState.address);
    }
    if (!selectedServices || selectedServices.length === 0) {
      navigate('/choose-service');
    }

    if (loc.state) {
      sessionStorage.setItem('state', JSON.stringify(loc.state));
    }
  }, [selectedServices, navigate, loc.state]);

  function isZipCodeInRange(zipCode, range) {
    // Extraire les bornes de la plage
    const [start, end] = range.split('-').map(Number);

    // Convertir le zipCode en entier
    const zip = parseInt(zipCode, 10);

    console.log(start, "start")
    return zip >= start && zip <= end;
  }


  const checkZipCodeInRange = async (zip_code) => {
    try {
      const zoneResponse = await ZoneService.index(null, axiosPrivate, auth?.accessToken ? false : true);
      console.log(zoneResponse, "zone");

      if (zoneResponse) {
        const zipNumeric = parseInt(zip_code, 10);
        let isInRange = false;
        console.log(zipNumeric, "zipNumeric")


        for (const zone of zoneResponse) {

          if (isZipCodeInRange(zipNumeric, zone.zip_code)) {
            isInRange = true;
            break;
          }
        }

        return isInRange;
      } else {
        console.error("Erreur lors de la récupération des zones.");
      }
    } catch (error) {
      console.error("Erreur lors de l'appel de ZoneService.index :", error);
    }
  };



  const checkAdress = async () => {
    console.log("auth", auth);
    try {
      const adressResponse = await AddressService.index({ with: 'hotel' }, axiosPrivate, auth?.accessToken ? false : true);


      let matchingAddress = null;

      // Recherche et stockage de l'adresse correspondante
      for (let responseAddress of adressResponse) {
        if (responseAddress.street?.toLowerCase().trim() === address.street?.toLowerCase().trim()) {
          matchingAddress = responseAddress;
          break; // Arrêter la recherche dès que l'adresse correspondante est trouvée
        }
      }

      console.log(matchingAddress)

      if (matchingAddress) {
        setHotelAddress(matchingAddress)
        // Vérifie si l'adresse correspondante a un hôtel associé
        const hasHotelId = matchingAddress.hotel.length > 0;
        console.log('Matching address with hotel found:', matchingAddress);
        console.log('Has hotel ID:', hasHotelId);
        return hasHotelId;
      } else {
        console.log('No matching address found.');
        return false;
      }

    } catch (error) {
      console.error("Erreur lors du chargement des domaines:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const updatedState = { ...JSON.parse(sessionStorage.getItem('state') ?? '{}'), address };
    sessionStorage.setItem('state', JSON.stringify(updatedState));

    console.log("zone", await checkZipCodeInRange(address.zip_code))

    const isOneZone = await checkZipCodeInRange(address.zip_code);
    if (!isOneZone) {
      notify.warn('zone nn deservi');
      openModal2()
      return false;
    }

    const hasHotelId = await checkAdress();
    if (hasHotelId) {
      notify.warn('Ceci est adresse hotel');
      openModal()
      return;
    }
    if (!address.zip_code) {
      notify.warn('Veuillez saisir votre code postal');
    }
    navigate('/choose-therapist', { state: { address, selectedServices, duo } });
  };

  const handlePlaceSelect = (place) => {
    const addressComponents = place.address_components;
    const newAddress = {
      street: '',
      city: '',
      zip_code: '',
      street_number: '',
      state: '',
      state_code: '',
      country: '',
      country_code: '',
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    console.log("places", addressComponents)
    for (const component of addressComponents) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number':
          newAddress.street = `${component.long_name} ${newAddress.street}`;
          break;
        case 'route':
          newAddress.street = `${newAddress.street} ${component.long_name}`;
          break;
        case 'locality':
          newAddress.city = component.long_name;
          break;
        case 'postal_code':
          newAddress.zip_code = component.long_name;
          break;
        case 'administrative_area_level_1':
          newAddress.state = component.long_name;
          newAddress.state_code = component.short_name;
          break;
        case 'country':
          newAddress.country = component.long_name;
          newAddress.country_code = component.short_name;
          break;
        /*  case 'street_number':
           newAddress.street_number = component.long_name;
           break; */
        default:
          break;
      }
    }

    setAddress(newAddress);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <>
      <MenuHeader />
      <Nav page="adresse" />

      <div className="container ">
        <div className="container-location">
          <h2>Où souhaitez-vous le soin ?</h2>

          <div className="form-group">
            <label htmlFor="street">Saisissez une adresse</label>
            <Autocomplete
              apiKey="AIzaSyBKj64n9saFmW2RqHkLJd97DHMmfpgc514"
              onPlaceSelected={handlePlaceSelect}
              options={{
                types: ['address'],
                componentRestrictions: { country: 'CH' },
              }}
              style={{ width: '100%', padding: '0 15px', height: '60px', border: '0', borderRadius: '20px', boxShadow: '0px 8px 50px 0px rgba(0, 0, 0, 0.06)' }}
            />
          </div>
        </div>
        {address.lat && address.lng && (
          <form onSubmit={handleSubmit}>
            <div className="container-location">
              <div className="form-group">
                {/* <label htmlFor="street">Rue</label> */}
                <input
                  type="hidden"
                  id="street"
                  className="form-control"
                  value={address.street}
                  onChange={(e) => setAddress({ ...address, street: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                {/* <label htmlFor="state">Canton</label> */}
                <input
                  type="hidden"
                  id="state"
                  className="form-control"
                  value={address.state}
                  onChange={(e) => setAddress({ ...address, state: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                {(!address.zip_code || address.zip_code.length < 8) && (
                  <>
                    <label htmlFor="postalCode">Code postal</label>
                    <input
                      type="text"
                      id="postalCode"
                      className="form-control"
                      value={address.zip_code}
                      onChange={(e) => setAddress({ ...address, zip_code: e.target.value })}
                      required
                    />
                  </>
                )}
              </div>
              <div className="form-group">
                {/* <label htmlFor="city">Ville</label> */}
                <input
                  type="hidden"
                  id="city"
                  className="form-control"
                  value={address.city}
                  onChange={(e) => setAddress({ ...address, city: e.target.value })}
                  required
                />
              </div>
            </div>

            <div className='btn-bottom'>
              <button type="submit" className="btn btn-primary">
                Suivant
              </button>
            </div>
          </form>
        )}

      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="contentModal">

          <div className="modal">
            <p className="modal-text">
              Ceci est l’adresse d’un hotel partenaire, vous allez basculer sur le choix “FlySPA à l’hôtel”
            </p>
            <h2 className="modal-hotel-name"> {hotelAddress?.hotel?.name}
              <span className="modal-rating">⭐ 4.9/5</span>
            </h2>
            <p className="modal-distance">A 2 km du Centre Ville</p>
            <p className="modal-address">
              Adresse: <span className='address-detail'>{hotelAddress?.street_number} {hotelAddress?.street}, {hotelAddress?.zip_code} {hotelAddress?.city}, {hotelAddress?.country}</span>
            </p>
            <div className="modal-button-container">
              <button className="modal-cancel-button" onClick={closeModal}>Annuler</button>
              <button className="modal-ok-button" onClick={onConfirm}>OK</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={closeModal2}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="zone-not-served-container">
          <div className="popup-box">
            <button onClick={closeModal2} className="close-btn"><img src={croix} alt="Fermer" /></button>
            <h2>Zone Non Desservie</h2>
            <p>
              Restez informé quand nos services seront disponible à cette adresse.
              Merci de saisir votre email.
            </p>
            <form onSubmit={handleSubmitEmail}>
              <label htmlFor="email">Adresse email *</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="send-btn">Envoyer</button>
            </form>
            <button onClick={onContact} className="support-btn">Contacter le support</button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ChooseLocation;