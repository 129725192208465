// ChooseLocation.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { endpoints } from '../utils/utils';
import MenuHeader from './MenuHeader';
import useLogout from '../hooks/useLogout';

const Profile = () => {

  const [user, setUser] = useState();
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();

    const signOut = async () => {
         await logout();
        navigate('/login');
    }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    /* let toolTip = document.getElementById("tooltip");

    window.addEventListener("mousemove", toolTipXY);

    function toolTipXY(e) {
        let x = e.clientX,
            y = e.clientY;
        toolTip.style.top = y + 20 + "px";
        toolTip.style.left = x + 20 + "px";
    } */

    const getUsers = async () => {
        try {
            const response = await axiosPrivate.get(endpoints.getProfil);

            console.log(response.data);
            isMounted && setUser(response.data);
        } catch (err) {
            console.error(err);
           navigate('/login', { state: { from: location }, replace: true });
        }
    }

    getUsers();


    return () => {
        isMounted = false;
        controller.abort();
    }
},[] )
  return (
    <>
    <MenuHeader />

    <div className="container container-profile">
      <div className="name">{user?.user?.firstname} {user?.user?.lastname}</div>
      <div className="lieu">{user?.user?.primary_address?.city}</div>
      <div className="adress">
        <h3>Adresse</h3>
        <div className="rue">{user?.user?.primary_address?.street} {user?.user?.primary_address?.street_number}<br /> {user?.user?.primary_address?.zip_code} {user?.user?.primary_address?.city}<br /> {user?.user?.phone}</div>
        <a href="/editadresse" className="edit">Edit</a>
      </div>
      <div className="list">
        <ul>
          <li>
            <a href="/editprofile">Modifier mon profil</a>
          </li>
          <li>
            <a href="/commande">Mes commande</a>
          </li>
          <li>
            <a href="#">Carte de paiement</a>
          </li>
          <li>
            <a href="#">CGU et Politique de Confidentialité </a>
          </li>
          <li className="logout">
            <a href="#" onClick={signOut}>Déconnexion</a>
          </li>
        </ul>
      </div>
    </div>
    </>
  );
}

export default Profile;
