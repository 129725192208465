import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import axios from 'axios';
import PromotionService from '../services/promotion.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';

const Voucher = () => {
    const navigate = useNavigate();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [showAddressField, setShowAddressField] = useState(false);
    const [voucherDetails, setVoucherDetails] = useState({
        amount: '',
        zip_code: '',
        deliveryAddress: '',
        validity: '1 year',
        code: '',
        client_id: auth?.user?.id,
    });

    const handleToggle = () => {
        setShowAddressField(!showAddressField);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setVoucherDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };


    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let promoCode = '';
        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            promoCode += characters.charAt(randomIndex);
        }
        setVoucherDetails((prevDetails) => ({
            ...prevDetails,
            code: promoCode,
        }));

        const creationDate = new Date();
        const validityPeriod = voucherDetails.validity === '1 year' ? 1 : parseInt(voucherDetails.validity, 10);
        const validityDate = new Date(creationDate.setFullYear(creationDate.getFullYear() + validityPeriod));



        console.log({ voucherDetails })

        const payload = {
            name: 'Voucher Purchase',
            code: promoCode,
            date: new Date().toISOString().split('T')[0],
            date_creation: new Date().toISOString().split('T')[0],
            date_validity: validityDate.toISOString().split('T')[0],
            type: 'FLATT_OFF',
            amount: voucherDetails.amount,
            assigned_to: 'All',
            total_usage: 1,
            usage_per_customer: 1,
            notifyClients: false,
            is_active: 1,
            client_id: auth.user.id,
            zip_code: voucherDetails.zip_code,
            // state: 'Geneva',
            // state_code:'GV',
            // country:'CH',
            // country_code:'CH',
            // duration: 1,
            // status: 1,
        };

        console.log({ payload })

        try {
            const response = await PromotionService.store(payload, axiosPrivate);
        } catch (error) {

        }
    };

    return (
        <>
            <Header />

            <div className="container buy-container">
                <h2>Acheter un bon</h2>
                <form onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <label htmlFor="amount">Acheter un bon de </label>
                        <input
                            type="number"
                            id="amount"
                            name="amount"
                            className="form-control"
                            placeholder="Montant"
                            value={voucherDetails.amount}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="zip_code">Code postal</label>
                        <input
                            type="text"
                            id="zip_code"
                            name="zip_code"
                            className="form-control"
                            placeholder="Code postal"
                            value={voucherDetails.zip_code}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="validity">Validité</label>
                        <select
                            id="validity"
                            name="validity"
                            className="form-control"
                            value={voucherDetails.validity}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="1 year">1 an</option>
                            <option value="2 years">2 ans</option>
                        </select>
                    </div>
                    <div
                        className="giftCard"
                        style={{ borderColor: showAddressField ? '#393D44' : '' }}
                    >
                        <div
                            className="text-wrapper"
                            style={{ color: showAddressField ? '#393D44' : '' }}
                        >
                            Carte cadeau physique
                        </div>
                        <label className="switch">
                            <input
                                type="checkbox"
                                id="toggle"
                                onChange={handleToggle}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>
                    {showAddressField && (
                        <div className="form-group">
                            <label htmlFor="deliveryAddress">Adresse de livraison</label>
                            <input
                                type="text"
                                id="deliveryAddress"
                                name="deliveryAddress"
                                className="form-control"
                                placeholder="Adresse"
                                value={voucherDetails.deliveryAddress}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    )}
                    <button type="submit" className="btn btn-primary gift-btn">
                        Continuer vers le paiement
                    </button>
                </form>
            </div>
        </>
    );
};

export default Voucher;
