import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuHeader from './MenuHeader';
import Nav from './Nav';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import DomainService from '../services/domain.service';
import { useNotifications } from '../context/NotificationProvider';
import useAuth from '../hooks/useAuth';
import remove from '../assets/images/remove.png';
import people from '../assets/images/people.png';

function ChooseService() {
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([{ service: null, productId: null, show: false, show_categories: false, show_description: false, product: null, category: null }]); // État pour les choix de soin par personne
  //const [expandedService, setExpandedService] = useState([{ service: null, productId: null }]); // État pour les choix de soin par personne
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const notify = useNotifications();
  const { auth } = useAuth();
  //const loc = useLocation();

  const [isActive, setIsActive] = useState(false);

  const toggleSwitch = () => {
    setIsActive(!isActive);
  };
  const handleCategorySelect = (index, category, show, show_description) => {
    const updatedServices = [...selectedServices];
    updatedServices[index].category = category;
    updatedServices[index].show_description = show_description;
    updatedServices[index].show = show;
    updatedServices[index].product = null;
    updatedServices[index].productId = null;
    setSelectedServices(updatedServices);
  };
  const handleServiceSelect = (index, service, show_categories, show_description) => {
    const updatedServices = [...selectedServices];
    updatedServices[index].service = service;
    updatedServices[index].show_categories = show_categories;
    updatedServices[index].show_description = show_description;
    updatedServices[index].product = null;
    updatedServices[index].productId = null;
    setSelectedServices(updatedServices);
  };

  const handleProductSelect = (index, productId, product) => {
    const updatedServices = [...selectedServices];
    updatedServices[index].productId = productId;
    updatedServices[index].product = product;
    setSelectedServices(updatedServices);
  };

  const addPerson = () => {
    if (selectedServices.length < 4) {
      setSelectedServices([...selectedServices, { service: null, show_categories: false, show: false, show_description: false, productId: null, category: null, product: null }]);
    }
  };
  const removePerson = (index) => {
    setSelectedServices(selectedServices.filter((_, i) => i !== index));
  };

  const fetchDomains = async () => {
    console.log("auth", auth)
    try {
      const domaineResponse = await DomainService.index({
        with: 'media,categories.media,categories.subCategories.media,categories.subCategories.products.media',
        sort: 'ordering'
      }, axiosPrivate, auth?.accessToken ? false : true);
      const activeDomains = domaineResponse.filter(domain => domain.is_active === true);
      setServices(activeDomains);
      console.log(activeDomains)
    } catch (error) {
      console.error("Erreur lors du chargement des domaines:", error);
    }
  };

  useEffect(() => {
    const savedState = JSON.parse(sessionStorage.getItem('chooseServiceState') ?? '{}');

    if (savedState.selectedServices) {
      setSelectedServices(savedState.selectedServices);
    }

    if (savedState.isActive !== undefined) {
      setIsActive(savedState.isActive);
    }

    fetchDomains();
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();
    const allProductsNull = selectedServices.some(service => service.product === null);

    if (allProductsNull) {
      notify.warn('Merci de selectionner un produit');
      return;
    }
    sessionStorage.setItem(
      'chooseServiceState',
      JSON.stringify({ selectedServices, isActive })
    );
    navigate('/choose-location', { state: { selectedServices, duo: selectedServices.length === 2 && isActive } });
  };

  return (
    <>
      <MenuHeader />
      <Nav page="service" />
      <div className={`container container-location row-${selectedServices.length}`}>
        <h2>Choisissez le type de soin</h2>
        <form onSubmit={handleSubmit}>
          <div className='content-row'>
            {selectedServices?.map((selectedService, index) => (
              <div key={index} className="person-services">
                {selectedServices.length > 1 && (
                  <h3 className='person-title'>

                    <span><img className='people' src={people} alt="Supprimer" />{index === 0 ? "Moi" : "Invité(e) " + (index)}</span>
                    {index > 0 && (
                      <button type='button' className='person-remove' onClick={() => removePerson(index)}>
                        <img className='remove' src={remove} alt="Supprimer" />
                      </button>
                    )}
                  </h3>
                )}
                <div key={index} className="services-container">
                  {(
                    selectedService.show_categories && selectedService?.service?.categories?.map(category => category?.sub_categories?.map(service => {

                      return (
                        <div key={service.id} className="service-wrapper">
                          <div
                            className={`service-card ${selectedService.category?.id === service.id && !selectedService.show_description ? 'selected' : ''}`}
                            style={{
                              background: `linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${service?.media[0]?.original_url}) no-repeat center center / cover`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center center',
                              backgroundSize: 'cover',
                            }}
                          >
                            <div className="service-info">
                              <div className="service-text">
                                <h3>{service.title}</h3>
                                <a onClick={() => handleCategorySelect(index, service, false, true)} className="more-info">Plus d'infos &gt;</a>
                              </div>
                              <button
                                type="button"
                                className="select-button"
                                onClick={() => handleCategorySelect(index, service, true, false)}
                              >
                                Sélectionner
                              </button>
                              <button
                                type="button"
                                className={`btn-outline-primary selected`}
                              >
                                &gt;
                              </button>
                            </div>
                          </div>
                          {(selectedService.category?.id === service.id && selectedService.show === true) && (
                            <div className='form-duree'>
                              <div className="form-group">
                                <div className="btn-group group-3" role="group">
                                  {service.products.filter(product => !product.is_hidden).map(product => (
                                    <button
                                      type="button"
                                      key={product.id}
                                      className={`btn-default ${selectedService?.productId === product.id ? 'btn-checked' : ''}`}
                                      onClick={() => handleProductSelect(index, product.id, product)}
                                    >
                                      <span>{product.duration} min</span>
                                      <br />
                                      <span className='price'>{product.price} chf</span>
                                    </button>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                          {(selectedService.category?.id === service.id && selectedService.show_description === true) && (
                            <div className='service-description'>
                              {service?.description}
                            </div>
                          )}
                        </div>
                      )
                    })))}
                  {(
                    !selectedService.show_categories && services.map(service => {
                      return (
                        <div key={service.id} className="service-wrapper">
                          <div
                            className={`service-card ${selectedService.service?.id === service.id && !selectedService.show_description ? 'selected' : ''}`}
                            style={{
                              background: `linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${service?.media[0]?.original_url}) no-repeat center center / cover`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center center',
                              backgroundSize: 'cover',
                            }}
                          >
                            <div className="service-info">
                              <div className="service-text">
                                <h3>{service.title}</h3>
                                <a onClick={() => handleServiceSelect(index, service, false, true)} className="more-info">Plus d'infos &gt;</a>
                              </div>
                              <button
                                type="button"
                                className="select-button"
                                onClick={() => handleServiceSelect(index, service, true, false)}
                              >
                                Sélectionner
                              </button>
                            </div>
                          </div>
                          {(selectedService.service?.id === service.id && selectedService.show_description === true) && (
                            <div className='service-description'>
                              {service?.description}
                            </div>
                          )}
                        </div>
                      )
                    })
                  )}
                </div>
              </div>
            ))}

          </div>
          {selectedServices.length === 2 && (
            <div className='btn-grp'>
              <div className='duo'>
                <div className='info'>
                  Massage DUO <i className="fa fa-info-circle"></i>
                </div>
                <div className='toggle'>
                  <div className={`toggle-switch ${isActive ? 'active' : ''}`} onClick={toggleSwitch}></div>
                </div>
              </div>
            </div>
          )}

          <div className='btn-grp'>
            <button type="button" onClick={addPerson} className="btn-add mt-4" disabled={selectedServices.length >= 4}>
              Ajouter une personne
            </button>
            <button type="submit" className="btn-next mt-4">
              Suivant
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ChooseService;
