import React, { useState, useEffect } from "react";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import UserService from "../services/user.service";
import HeaderTherapist from './HeaderTherapist';
import useAuth from '../hooks/useAuth'; import Informations from './Informations';
import Header from './Header';
import user from '../assets/images/default.png';
import ReviewList from './ReviewList';
import { useNotifications } from '../context/NotificationProvider';
import { useNavigate } from 'react-router-dom';


const FavFlyPro = () => {
    const [therapists, setTherapists] = useState([]);
    const [address, setAddress] = useState(null);
    const [selectedTherapist, setSelectedTherapist] = useState([]);
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [filters, setFilters] = useState({
        with: 'primary_address,zones,managers,media,categories,therapist_bookings,slots',
        page: currentPage,
        per_page: perPage,
        sort: 'firstname'
    });
    const navigate = useNavigate();
    const notify = useNotifications();


    const [currentTherapist, setCurrentTherapist] = useState('');
    const [modalIsOpen2, setIsOpen2] = useState(false);


    const openModal2 = (therapist) => {
        console.log(therapist)
        setCurrentTherapist(therapist)
        setIsOpen2(true)
    };
    const closeModal2 = () => setIsOpen2(false);


    const fetchTherapists = async (savedAddress) => {
        try {
            const therapistResponse = await UserService.indexByRole(filters, 'ROLE_FLYPRO', axiosPrivate, auth.user ? false : true);
            console.log("Fetched Therapists Response:", therapistResponse);

            const addressZip = savedAddress ? JSON.parse(savedAddress).address.zip_code : null;

            if (addressZip) {
                const filteredTherapists = therapistResponse.data.filter((therapist) => {
                    return therapist.zones.some(zone => {
                        const [startZip, endZip] = zone.zip_code.split('-').map(Number);
                        return addressZip >= startZip && addressZip <= endZip;
                    });
                });

                setTherapists(filteredTherapists);
                console.log("Filtered Therapists:", filteredTherapists);
            } else {
                setTherapists(therapistResponse.data);
            }
        } catch (error) {
            console.error("Error while fetching therapists:", error);
        }
    };
    const handleSubmit = (event) => {
        event?.preventDefault();

        const selectedTherapists = selectedTherapist;
        if (selectedTherapists.length !== 1) {
            notify.warn('Veuillez sélectionner un thérapeute');
            return;
        }

        const paymentState = {
            selectedTherapist: selectedTherapists,
            address: address,
        };
        console.log('fypro2', paymentState);
        sessionStorage.setItem('state', JSON.stringify(paymentState));
        if (!auth || Object.keys(auth).length === 0) {
            sessionStorage.setItem('redirectAfterLogin', '/fav-flypro');
            navigate('/login');
            return;
        }
        navigate('/flypro-service', {
            state: paymentState
        });
    };
    function addedTherapist(therapist) {
        setSelectedTherapist((prevTherapists) => {
            if (therapist.id === 0) {
                return [therapist];
            } else {
                const updatedTherapists = prevTherapists.some(t => t.id === 0) ? [] : prevTherapists;
                if (updatedTherapists.some(t => t.id === therapist.id)) {
                    return updatedTherapists.filter(t => t.id !== therapist.id);
                } else {
                    return [...updatedTherapists, therapist];
                }
            }
        });
    }
    useEffect(() => {
        const savedAddress = sessionStorage.getItem('address');
        console.log(savedAddress);
        if (savedAddress) {
            setAddress(JSON.parse(savedAddress));
        }
        fetchTherapists(savedAddress);
    }, []);

    return (
        <>
            <Header />
            <div className={`container ${!modalIsOpen2 && 'container-therapeut'}`}>
                {!modalIsOpen2 && <h2>Choisissez votre masseur préféré</h2>}
            </div>
            <form className="form-masseur" onSubmit={handleSubmit}>
                {modalIsOpen2 ? <>
                    <div className="contentModal">

                        <HeaderTherapist currentTherapist={currentTherapist} closeModal2={closeModal2} />
                        <ReviewList />
                        <Informations  currentTherapist={currentTherapist} />
                    </div></> : <>
                    <div className={`container ${!modalIsOpen2 && 'container-therapeut'}`}>
                        {therapists.length > 0 && (<div className="form-group group-masseur">

                            {therapists?.map((therapist) => (
                                <div key={therapist.id} className="therapist-item" onClick={() => addedTherapist(therapist)}>
                                    <img onClick={() => openModal2(therapist)} src={therapist.media.length ? therapist.media[0].original_url : user} alt={therapist?.name} className="therapist-image" />
                                    <div className="therapist-info">
                                        <p className="name">{therapist?.firstname}</p>
                                        <p className="note">{therapist?.extra?.rating}/5</p>
                                        <button
                                            type="button"
                                            className={`btn-outline-primary ${selectedTherapist.some(t => t.id === therapist.id) ? 'selected' : ''}`}
                                        >
                                            &gt;
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>)}</div></>}

                <div className='btn-bottom'>
                    <button type="submit" className="btn btn-primary">
                        Suivant
                    </button>
                </div>

            </form>



            {/* <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="contentModal">
                    <div className="headerModal modal-icone">
                        <a onClick={closeModal}>
                            <img src={back} alt="Fermer" />
                        </a>
                        <a onClick={closeModal}>
                            <img src={croix} alt="Fermer" />
                        </a>
                    </div>
                    <div className="form-inscription">
                        <form onSubmit={handleSubmitFilter}>
                            <label>Sexe</label>
                            <div className="form-choix">
                                <div className="form-radio">
                                    <input
                                        type="radio"
                                        name="civility"
                                        value="MR"
                                        checked={formData.civility === "MR"}
                                        id="monsieur"
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="monsieur">Homme</label>
                                </div>
                                <div className="form-radio">
                                    <input
                                        type="radio"
                                        name="civility"
                                        value="MS"
                                        id="madame"
                                        checked={formData.civility === "MS"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="madame">Femme</label>
                                </div>

                            </div>
                            <div className='flex-row gap-10'>
                                <div className="form-text">
                                    <label>Language</label>
                                    <select name="langue" onChange={handleChange}>
                                        <option value="fr">Français</option>
                                        <option value="en">Anglais</option>

                                    </select>
                                </div>
                            </div>
                            <div className='flex-row gap-10'>
                                <div className="form-text">
                                    <label>Niveau d’éxpercience</label>
                                    <select name="niveau" onChange={handleChange}>
                                        <option value="1">Level 1</option>
                                        <option value="2">Level 2</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-button">
                                <button className='btn-submit' type="submit">Filter</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal> */}


        </>

    );
};

export default FavFlyPro;
