import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from './Nav';
import { useLocation } from 'react-router-dom';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import star2 from '../assets/images/star2.svg';
import pencil from '../assets/images/pencil.svg';
import questionmark from '../assets/images/questionmark.svg';
import therapeut1 from '../assets/images/therapeut1.png';
import BookingService from '../services/booking.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import MenuHeader from './MenuHeader';
import Header from './Header';

// const stripePromise = loadStripe('pk_test_51IYwAqDh1V63ycuimBrVJgxaQkDKtUdxkpFmYv38yV8qmiCEeGCKTvlQi7cIAjepUBVaY7Tyx4xSAw8qjBdbB1ze00OanEXi4X'); // Remplacez par votre clé publique Stripe

function PaymentForm({ totalPriceCHF }) {
  // const stripe = useStripe();
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const { address, duo, totalDuration, totalPrice, selectedServices, selectedTherapist, selectedDate, selectedTime } = location.state || JSON.parse(localStorage.getItem('state') ?? '{}');
  const { lng, lat, zip_code, street, street_number, state, state_code, country, country_code } = address || {};

  useEffect(() => {
    if (!selectedServices || selectedServices.length === 0) {
      navigate('/choose-service');
    } else if (!address) {
      navigate('/choose-address');
    }
    if (!selectedTherapist || selectedTherapist.length === 0) {
      navigate('/choose-therapist');
    }
  }, [selectedServices, selectedTherapist, address, navigate]);


  const [message, setMessage] = useState('');
  const [promotions, setPromotions] = useState([]);
  const [promoCode, setPromoCode] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(totalPrice);
  const [alertMessage, setAlertMessage] = useState('');
  /*  const [termsAccepted, setTermsAccepted] = useState(false);
   const [paymentError, setPaymentError] = useState(null);
   const [paymentProcessing, setPaymentProcessing] = useState(false);
   const [formData, setFormData] = useState({
     description: '',
   }) */
  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const response = await axiosPrivate.get('promotions');
        setPromotions(response.data);
      } catch (error) {
        console.error('Error fetching promotions:', error);
      }
    };

    fetchPromotions();
  }, []);

  const handlePromoCodeValidation = () => {
    const promo = promotions.find((p) => p.code === promoCode);

    if (promo) {
      const currentDate = new Date();
      const validityDate = new Date(promo.date_validity);

      if (currentDate <= validityDate) {
        const discount = promo.amount;
        setDiscountedPrice(totalPrice - discount);
        setAlertMessage('Promo code applied');
      } else {
        setAlertMessage('Promo code has expired');
      }
    } else {
      setAlertMessage('Invalid promo code');
    }
    const timer = setTimeout(() => setAlertMessage(''), 3000);

    return () => clearTimeout(timer);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await BookingService.store({
      date: `${selectedDate?.split('T')[0]} ${selectedTime}`,
      start_before: `${selectedDate?.split('T')[0]} ${selectedTime}`,
      duration: totalDuration,
      longitude: lng,
      latitude: lat,
      zip_code: zip_code,
      therapist_id: selectedTherapist?.map(therapist => therapist?.id),
      street: street,
      state: state,
      state_code: state_code,
      country: country,
      country_code: country_code,
      street_number: street_number,
      client_id: auth?.user?.id,
      status: 'CREATED',
      total_fare: selectedServices
        .map(service => service.product.price)
        .reduce((acc, price) => acc + price, 0),
      subtotal: selectedServices
        .map(service => service.product.price)
        .reduce((acc, price) => acc + price, 0),
      duo: duo,
      product_id: selectedServices[0].product.id,
      description: message
    }, axiosPrivate);

    navigate('/confirmation');

    localStorage.removeItem('state');
    sessionStorage.removeItem('state');
    sessionStorage.removeItem('chooseServiceState');
    localStorage.removeItem('chooseServiceState');
    /* if (!termsAccepted) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    setPaymentProcessing(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setPaymentError(error.message);
      setPaymentProcessing(false);
      return;
    }

    console.log('Paiement soumis avec succès ! Redirection vers la page de confirmation.'); */
    // setTimeout(() => {
    //   navigate('/confirmation');
    // }, 2000);

  };

  const addMinutesToTime = (time, duration) => {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes + duration);

    return date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
  };

  const formatSelectedDate = (date) => {
    const options = { month: 'short', day: 'numeric' };
    return new Intl.DateTimeFormat('fr-FR', options).format(new Date(date));
  };

  const previousPathname = sessionStorage.getItem("previousPath");
  // sessionStorage.removeItem("previousPath");

  return (
    <>
      {previousPathname === "/flypro-booking" ? (
        <>
          <Header />
        </>
      ) : (
        <>
          <MenuHeader />
          <Nav page="payment" />
        </>
      )}
      <div className="content-wrapper">
        <div className="content-wrapper duo-pay">

          {selectedServices &&
            selectedServices.map((service, serviceIndex) => {
              const serviceTherapist =
                selectedTherapist[serviceIndex % selectedTherapist.length];

              return (
                <div className="column" key={service.id}>
                  <div className="summary-box">
                    {serviceTherapist && (
                      <div className="name">
                        <div className="image" style={{ backgroundImage: `url(${serviceTherapist.media.length ? serviceTherapist.media[0].original_url : therapeut1})` }}></div>
                        <div className="title">
                          <div className="text-wrapper-2">
                            {serviceTherapist.firstname}
                          </div>
                          <div className="rating">
                            <div className="stars">
                              <div className="text-wrapper-3">
                                <img className="https-app" src={star2} alt="Rating" />
                              </div>
                            </div>
                            <div className="text-wrapper-4">{serviceTherapist?.extra?.rating}/5</div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="div-2">
                      <div className="text-wrapper-5">{service.product.name}</div>
                    </div>

                    <div className="div-2">
                      <p className="text-wrapper-5">
                        {formatSelectedDate(selectedDate)} {selectedTime} –{" "}
                        {addMinutesToTime(selectedTime, totalDuration)}
                      </p>
                      <div className="text-wrapper-6">{service.product.price} CHF</div>
                    </div>

                    <div className="div-2">
                      <p className="text-wrapper-5">
                        {address?.street} {address?.streetnumber}
                        <br />
                        {address?.zip_code} {address?.city}
                        <br />
                        {auth?.user?.phone}
                      </p>
                      <div className="text-wrapper-3">
                        <img className="https-app" src={pencil} alt="Edit" />
                      </div>
                    </div>

                    <div className="message-au-flypro">
                      <div className="title-2">
                        <div className="text-wrapper-8">
                          Message au flypro{" "}
                          <img className="https-app" src={questionmark} alt="Info" />
                        </div>
                      </div>
                      <input
                        type="text"
                        placeholder="Ex: Option de parking, code d'entrée, étage, n° de chambre, info de santé, etc."
                        className="form-message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <div className="column-2">
          <div className="total">
            <div className="price">
              <div className="list-item-2 list-item-3">
                <span>Total (TVA incluse)</span>
                <div className="text-wrapper-6">{totalPrice} CHF</div>
              </div>
              <div className="form-4 form-with-button">
                <input
                  type="text"
                  placeholder="Indiquer un code promo"
                  className="form-5"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <button className="form-3" onClick={handlePromoCodeValidation}>
                  Valider
                </button>
              </div>
              {alertMessage && <div className="alert">{alertMessage}</div>}
            </div>

            <div className="checkbox-2">
              <input type="checkbox" className="checkbox" id="cgu" defaultChecked />
              <label htmlFor="cgu" className="accepter-les-CGU-et">
                <span>Accepter </span>
                <span className="text-wrapper-9">les CGU</span>
                <span> et la </span>
                <span className="text-wrapper-9">Politique de Confidentialité</span>
                <span> *</span>
              </label>
            </div>
          </div>
          <button
            onClick={handleSubmit}
            className="button-validation"
            type="button"
          >
            {discountedPrice.toFixed(2)} CHF
          </button>
        </div>

      </div>
    </>
  );
}

function Payment() {
  return (
    <>
      <PaymentForm totalPriceCHF={120} />
    </>

  );
}

export default Payment;
