import React from 'react';

const ReviewList = () => {
  return (
    <div className="review-list">
      <div className='modal-therapist'>
        {/* <h3 className='info-title'>Avis (30 avis)</h3>
      <div className="reviews">
        <ReviewItem />
        <ReviewItem />
        <ReviewItem />
        <ReviewItem />
        <ReviewItem />
        <ReviewItem />
        <ReviewItem />
        <ReviewItem />
      </div> */}
      </div>
    </div>
  );
};

export default ReviewList;
