// Home.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuHeader from "./MenuHeader";


function Home() {
  const [selection, setSelection] = useState("service");
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem('state')) {
      sessionStorage.removeItem('state');
    }
    if (sessionStorage.getItem('chooseServiceState')) {
      sessionStorage.removeItem('chooseServiceState');
    }
  }, []);

  const handleSelectionChange = (event) => {
    setSelection(event.target.name);
  }

  const handleSelection = (event) => {
    switch (event.target.name) {
      case 'soin':
        navigate("/choose-service");
        break;
      case 'hotel':
        navigate("/hotels");
        break;
      case 'flyprofav':
        navigate("/location-pro");
        break;
      default:
        navigate("/choose-service");
        break;
    }

  }
  const handleGift = (event) => {
    navigate("/gifts");

  }
  return (
    <>
      <MenuHeader />
      <div className="page-home">
        <div className="container home flex-column gap-50 align-center">
          <img src="assets/images/logo-alt.svg" alt="Flyspa Logo"></img>
          <h1>Prêt pour votre massage?</h1>
          <div className="card">
            <div className="card-header card-selection">
              <button type="button" name="service" onClick={handleSelectionChange} className={selection === "service" ? "btn-primary active" : "btn-primary"}>Service {selection === "service" && (<img src="assets/images/arrow_down.svg" alt="icon" />)}</button>
              <button type="button" name="flypro" onClick={handleSelectionChange} className={selection === "flypro" ? "btn-primary active" : "btn-primary"}>Flypro {selection === "flypro" && (<img src="assets/images/arrow_down.svg" alt="icon" />)}</button>
            </div>
            <div className="card-body">
              {selection === "service" && (
                <>
                  <button type="button" name="soin" onClick={handleSelection} className="btn-primary active"><img src="assets/images/icon-soin.svg" alt="icon" />Choisissez votre soin </button>
                  <button type="button" name="hotel" onClick={handleSelection} className="btn-primary active"><img src="assets/images/icon-hotel.svg" alt="icon" />Choisir un hotel partenaire </button>
                </>
              )}
              {selection === "flypro" && (
                <>
                  <button type="button" name="flyprofav" onClick={handleSelection} className="btn-primary active"><img src="assets/images/icon-pro.svg" alt="icon" />Choisissez votre flypro </button>
                </>
              )}
            </div>
          </div>
          <button type="button" name="flypro" onClick={handleGift} className="btn-secondary active"><img src="assets/images/icon-gift.svg" alt="cadeau" />Faire un cadeau</button>
        </div>
      </div>
    </>
  );
}

export default Home;
