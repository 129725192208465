import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Nav = ({ page }) => {

  const isPassed = (currentPage, targetPage) => {
    const order = ["service", "therapist", "payment"];
    return order.indexOf(currentPage) > order.indexOf(targetPage);
  };

  const isCurrentOrPassed = (currentPage, targetPage) => {
    const order = ["service", "therapist", "payment"];
    return order.indexOf(currentPage) >= order.indexOf(targetPage);
  };

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div className="menu-nav">
      <div className="turn-back">
        <div className="frame">
          <div className="div" onClick={goBack} >{"<"} Retour</div>
        </div>
      </div>
      <ul>
        {["service", "therapist", "payment"].map((step, index) => (
          <li
            key={index}
            className={`${page === step ? "actif" : isPassed(page, step) ? "passed" : "service-item"}`}
          >
            {isCurrentOrPassed(page, step) ? (
              <Link className="step-link" to={`/${step === "service" ? "hotel-choose-service" : step === "therapist" ? "hotel-choose-therapist" : "payment"}`}>
                {step === "service" ? "Choisissez votre soin" :
                    step === "therapist" ? "Choisissez votre thérapeute" : "Paiement"}
              </Link>
            ) : (
              <span className="disabled-step">
                {step === "service" ? "Choisissez votre soin" :
                    step === "therapist" ? "Choisissez votre thérapeute" : "Paiement"}
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Nav;
