import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Header from './Header'
import NavHotel from './NavHotel';
import user from '../assets/images/default.png';
import croix from "../assets/images/close.png";
import back from "../assets/images/back.png";
import MenuHeader from './MenuHeader';
import TherapistService from '../services/therapist.service';
import UserService from '../services/user.service';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Modal from "react-modal";
import DateTimeSelector from './DateTimeSelector';
import HeaderTherapist from './HeaderTherapist';
import ReviewList from './ReviewList';
import Informations from './Informations';
import { useNotifications } from '../context/NotificationProvider';
import useAuth from '../hooks/useAuth';

const customStyles = {
    content: {
        width: "33%",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

const customStyles2 = {
    content: {
        width: "100%",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};


Modal.setAppElement("#root");

function ChooseTherapist() {
    const [selectedTherapist, setSelectedTherapist] = useState([]);
    const [therapists, setTherapist] = useState([]);
    const [currentTherapist, setCurrentTherapist] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIsOpen2, setIsOpen2] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(null);
    const { auth } = useAuth();
    const loc = useLocation();
    const [state, setState] = useState(loc.state || JSON.parse(sessionStorage.getItem('state') ?? '{}'));
    const { address, duo, selectedServices, hotel_address } = state;
    const { lng, lat, zip_code } = address || {};
    const notify = useNotifications();
    const totalDuration = selectedServices?.reduce((total, service, index) => {
        const duration = service?.product?.duration ? parseInt(service.product.duration, 10) : 0;
        return total + duration;
    }, 0);
    const totalPrice = selectedServices?.reduce((total, service, index) => {
        const price = service?.product?.price ? service.product.price : 0;
        return total + price;
    }, 0);
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [formData, setFormData] = useState({
        langue: "",
        niveau: "",
        civility: "",
    });

    function formatISODate(isoDate) {
        return new Date(isoDate).toLocaleDateString('en-CA').split("T")[0];
    }

    function addedTherapist(therapist) {
        setSelectedTherapist((prevTherapists) => {
            if (therapist.id === 0) {
                return [therapist];
            } else {
                const updatedTherapists = prevTherapists.some(t => t.id === 0) ? [] : prevTherapists;
                if (updatedTherapists.some(t => t.id === therapist.id)) {
                    return updatedTherapists.filter(t => t.id !== therapist.id);
                } else if (!duo) {
                    return [therapist];
                } else {
                    return [...updatedTherapists, therapist];
                }
            }
        });
    }

    useEffect(() => {
        const savedState = sessionStorage.getItem('state');
        if (savedState) {
            const parsedState = JSON.parse(savedState);
            console.log(parsedState);
            setState(parsedState);

            if (parsedState.selectedDate) setSelectedDate(new Date(parsedState.selectedDate));
            if (parsedState.selectedTime) setSelectedTime(parsedState.selectedTime);
            if (parsedState.selectedTherapist) setSelectedTherapist(parsedState.selectedTherapist);

        }
    }, []);

    useEffect(() => {
        if (!selectedServices || selectedServices.length === 0) {
            navigate('/hotel-choose-service');
        }
    }, [selectedServices, address, navigate]);

    useEffect(() => {
        const now = new Date();
        let timeToUse = selectedTime;
        if (!selectedTime) {
            const nextQuarterHour = Math.ceil(now.getMinutes() / 15) * 15;
            now.setMinutes(nextQuarterHour);
            now.setSeconds(0);
            timeToUse = now.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
        }
        if (selectedDate) {
            fetchTherapist(formatISODate(selectedDate), timeToUse, totalDuration, lat, lng, zip_code);
        }
        updateDaysToShow(new Date());
    }, [selectedDate, selectedTime, totalDuration, lat, lng, zip_code]);

    const fetchTherapist = async (date, time, duration, longitude, latitude, zip_code) => {
        console.log(date, time, duration, longitude, latitude, zip_code)
        console.log({ hotel_address })
        console.log(selectedServices);
        try {

            // const therapistResponse = await TherapistService.store({
            //     date: date,
            //     time: time,
            //     duration: selectedServices?.[0]?.service?.duration,
            //     longitude: hotel_address?.latitude,
            //     latitude: hotel_address?.longitude,
            //     zip_code: hotel_address?.zip_code,
            // }, axiosPrivate);
            // const response = await UserService.indexByRole(null, 'ROLE_FLYPRO', axiosPrivate);
            const response = await UserService.indexByRole({primary_address: hotel_address }, 'ROLE_FLYPRO', axiosPrivate);
            console.log("response API : ", response)
            setTherapist(response)
            console.log("list of therapist : ", therapists)
            // setTherapist(therapistResponse);
            // console.log(therapistResponse)
        } catch (error) {
            console.error("Erreur lors du chargement des thérapeutes:", error);
        }
    };

    const openModal = () => setIsOpen(true);
    const openModal2 = (therapist) => {
        console.log(therapist)
        setCurrentTherapist(therapist)
        setIsOpen2(true)
    };

    const closeModal = () => setIsOpen(false);
    const closeModal2 = () => setIsOpen2(false);

    const handleSubmit = (event) => {
        event?.preventDefault();

        const selectedTherapists = selectedTherapist[0]?.id === 0 ?
            (duo && therapists?.length >= 2 ? [therapists[0], therapists[1]] :
                !duo && therapists?.length >= 1 ? [therapists[0]] : []) : selectedTherapist;

        if (duo && selectedTherapists.length !== 2) {
            notify.warn('Veuillez sélectionner deux thérapeutes pour un duo.');
            return;
        } else if (!duo && selectedTherapists.length !== 1) {
            notify.warn('Veuillez sélectionner un thérapeute.');
            return;
        }

        const paymentState = {
            address,
            duo,
            totalDuration,
            totalPrice,
            selectedServices,
            selectedTherapist: selectedTherapists,
            selectedDate: selectedDate.toLocaleDateString('en-CA'),
            selectedTime
        };

        sessionStorage.setItem('state', JSON.stringify(paymentState));
        if (!auth || Object.keys(auth).length === 0) {
            sessionStorage.setItem('redirectAfterLogin', '/hotel-choose-therapist');
            navigate('/login');
            return;
        }
        navigate('/hotel-payment', {
            state: paymentState
        });
    };


    const handleSubmitFilter = (event) => {
        event.preventDefault();
        fetchTherapist(formData);
        closeModal();
    };


    const handleMonthChange = (direction) => {
        const newDate = new Date(selectedDate);
        newDate.setMonth(newDate?.getMonth() + direction);
        setSelectedDate(newDate);
        updateDaysToShow(newDate);
    };

    const handleDayChange = (direction) => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + direction);

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        setSelectedDate(newDate);
        updateDaysToShow(newDate);
        if (selectedTime === null)
            return;
        fetchTherapist(formatISODate(newDate), selectedTime, totalDuration, lat, lng, zip_code);

    };

    function handleDayClick(date) {
        setSelectedDate(date);

        const now = new Date();
        let initialTime;

        console.log(now < new Date()
            ? 'disabled'
            : now?.toDateString() === selectedDate?.toDateString()
                ? 'selected'
                : '')

        if (selectedTime === null)
            return;

        if (date?.toDateString() === now?.toDateString()) {
            const nextQuarterHour = Math.ceil(now.getMinutes() / 15) * 15;
            now.setMinutes(nextQuarterHour);
            now.setSeconds(0);

            initialTime = now;
        } else {
            initialTime = new Date(date);
            initialTime.setHours(8, 0, 0, 0);
        }

        setSelectedTime(initialTime.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
        fetchTherapist(formatISODate(date), selectedTime, totalDuration, lat, lng, zip_code);
    }


    function handleHourClick(hour) {
        setSelectedTime(hour);
        fetchTherapist(formatISODate(selectedDate), hour, totalDuration, lat, lng, zip_code);
    }

    const handleHourChange = (direction) => {
        const now = new Date();
        let [hours, minutes] = selectedTime.split(':').map(Number);
        let totalMinutes = hours * 60 + minutes + direction * 15;


        if (selectedDate?.toDateString() === now?.toDateString()) {
            const currentTotalMinutes = now.getHours() * 60 + now.getMinutes();
            const nextQuarter = Math.ceil(currentTotalMinutes / 15) * 15;
            if (totalMinutes < nextQuarter) {
                totalMinutes = nextQuarter;
            }
        }

        const startOfDayMinutes = 8 * 60;
        const endOfDayMinutes = 22 * 60;

        if (totalMinutes < startOfDayMinutes) {
            totalMinutes = startOfDayMinutes;
        } else if (totalMinutes > endOfDayMinutes) {
            totalMinutes = endOfDayMinutes;
        }

        const newHours = Math.floor(totalMinutes / 60);
        const newMinutes = totalMinutes % 60;
        const newDate = new Date(selectedDate);
        newDate.setHours(newHours);
        newDate.setMinutes(newMinutes);
        newDate.setSeconds(0);

        setSelectedDate(newDate);
        setSelectedTime(newDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
        console.log("time selected", newDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
    };


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const getMonthName = (monthIndex) => {
        const date = new Date();
        date.setMonth(monthIndex);
        return date.toLocaleString('fr-FR', { month: 'long' });
    };

    const getDayName = (date) => {
        return date.toLocaleString('fr-FR', { weekday: 'short' });
    };

    const getNextHourSlots = (date) => {
        const now = new Date();
        const hours = [];

        if (date?.toDateString() === now?.toDateString()) {
            const minutes = now.getMinutes();
            const nextQuarterHour = Math.ceil(minutes / 15) * 15;
            now.setMinutes(nextQuarterHour);
            now.setSeconds(0);

            for (let i = 0; i < 57; i++) {
                const slot = new Date(now.getTime() + i * 15 * 60000);
                if (slot.getHours() >= 8 && slot.getHours() < 22) {
                    hours.push(slot.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
                }
            }
        } else {
            const startOfDay = new Date(date);
            startOfDay.setHours(8, 0, 0, 0);

            for (let i = 0; i < 57; i++) {
                const slot = new Date(startOfDay.getTime() + i * 15 * 60000);
                if (slot.getHours() >= 8 && slot.getHours() < 22) {
                    hours.push(slot.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
                }
            }
        }

        return hours;
    };

    const scrollLeft = () => {
        const container = document.querySelector('.row-day');
        container.scrollBy({ left: -100, behavior: 'smooth' });
    };

    const scrollRight = () => {
        const container = document.querySelector('.row-day');
        container.scrollBy({ left: 100, behavior: 'smooth' });
    };

    const getInitialDaysToShow = () => {
        const days = [];
        const today = new Date();
        for (let i = 0; i < 9; i++) {
            const day = new Date(today);
            day.setDate(today.getDate() + i);
            days.push(day);
        }
        return days;
    };

    const [daysToShow, setDaysToShow] = useState(() => getInitialDaysToShow());

    const updateDaysToShow = (baseDate) => {
        const days = [];
        for (let i = 0; i < 9; i++) {
            const day = new Date(baseDate);
            day.setDate(baseDate.getDate() + i);
            days.push(day);
        }
        setDaysToShow(days);
    };

    const hours = getNextHourSlots(selectedDate);
    // sessionStorage.removeItem("previousPath");

    return (
        <>
            <MenuHeader />
            <NavHotel page="therapist" />
            <div className={`container ${!modalIsOpen2 && 'container-therapeut'}`}>
                {!modalIsOpen2 && <h2>Choisissez votre masseur et la date</h2>}
            </div>
            <form className="form-masseur" onSubmit={handleSubmit}>
                {modalIsOpen2 ? <>
                    <div className="contentModal">

                        <HeaderTherapist currentTherapist={currentTherapist} closeModal2={closeModal2} />
                        <form className="form-masseur" onSubmit={handleSubmit}>
                            <DateTimeSelector selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                                selectedTime={selectedTime}
                                modalIsOpen2={modalIsOpen2}
                                setSelectedTime={setSelectedTime}
                                handleMonthChange={handleMonthChange}
                                handleDayChange={handleDayChange}
                                handleDayClick={handleDayClick}
                                handleHourClick={handleHourClick}
                                daysToShow={daysToShow}
                                hours={hours}
                                totalPrice={totalPrice}
                                scrollLeft={scrollLeft}
                                scrollRight={scrollRight}
                                openModal={openModal}
                                getMonthName={getMonthName}
                                getDayName={getDayName} />
                        </form>
                        <ReviewList />
                        <Informations />
                    </div></> : <>
                    <div className={`container ${!modalIsOpen2 && 'container-therapeut'}`}>
                        <DateTimeSelector
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            selectedTime={selectedTime}
                            setSelectedTime={setSelectedTime}
                            handleMonthChange={handleMonthChange}
                            handleDayChange={handleDayChange}
                            handleDayClick={handleDayClick}
                            handleHourClick={handleHourClick}
                            daysToShow={daysToShow}
                            hours={hours}
                            totalPrice={totalPrice}
                            scrollLeft={scrollLeft}
                            scrollRight={scrollRight}
                            openModal={openModal}
                            getMonthName={getMonthName}
                            getDayName={getDayName}
                        />
                        {therapists?.length > 0 && (<div className="form-group group-masseur">
                            <div className="therapist-item" onClick={() => { addedTherapist({ id: 0 }); }}>
                                <img src={user} alt="default" className="therapist-image" />
                                <div className="therapist-info">
                                    <p className="name">Aucune préférence </p>
                                    <button
                                        type="button"
                                        className={`btn-outline-primary default ${selectedTherapist.some(t => t.id === 0) ? 'selected' : ''}`}

                                    >
                                        &gt;
                                    </button>
                                </div>
                            </div>
                            
                            {therapists?.map((therapist) => (
                                <div key={therapist.id} className="therapist-item" onClick={() => addedTherapist(therapist)}>
                                    <img onClick={() => openModal2(therapist)} src={therapist?.media?.length ? therapist?.media[0].original_url : user} alt={therapist?.name} className="therapist-image" />
                                    <div className="therapist-info">
                                        <p className="name">{therapist?.firstname}</p>
                                        <p className="note">{therapist?.extra?.rating}/5</p>
                                        <button
                                            type="button"
                                            className={`btn-outline-primary ${selectedTherapist.some(t => t.id === therapist.id) ? 'selected' : ''}`}
                                        >
                                            &gt;
                                        </button>
                                    </div>
                                </div>
                            ))
                            }
                        </div>)}</div></>}

                <div className='btn-bottom'>
                    <button type="submit" className="btn btn-primary">
                        Suivant
                    </button>
                </div>

            </form>



            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="contentModal">
                    <div className="headerModal modal-icone">
                        <a onClick={closeModal}>
                            <img src={back} alt="Fermer" />
                        </a>
                        <a onClick={closeModal}>
                            <img src={croix} alt="Fermer" />
                        </a>
                    </div>
                    <div className="form-inscription">
                        <form onSubmit={handleSubmitFilter}>
                            <label>Sexe</label>
                            <div className="form-choix">
                                <div className="form-radio">
                                    <input
                                        type="radio"
                                        name="civility"
                                        value="MR"
                                        checked={formData.civility === "MR"}
                                        id="monsieur"
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="monsieur">Homme</label>
                                </div>
                                <div className="form-radio">
                                    <input
                                        type="radio"
                                        name="civility"
                                        value="MS"
                                        id="madame"
                                        checked={formData.civility === "MS"}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="madame">Femme</label>
                                </div>

                            </div>
                            <div className='flex-row gap-10'>
                                <div className="form-text">
                                    <label>Language</label>
                                    <select name="langue" onChange={handleChange}>
                                        <option value="fr">Français</option>
                                        <option value="en">Anglais</option>

                                    </select>
                                </div>
                            </div>
                            <div className='flex-row gap-10'>
                                <div className="form-text">
                                    <label>Niveau d’éxpercience</label>
                                    <select name="niveau" onChange={handleChange}>
                                        <option value="1">Level 1</option>
                                        <option value="2">Level 2</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-button">
                                <button className='btn-submit' type="submit">Filter</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>


        </>
    );
}

export default ChooseTherapist;
